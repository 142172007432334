
import { defineComponent } from "vue-demi";
import ExpertMain from "../components/expert/index.vue";

export default defineComponent({
  name: "Expert",
  components: {
    ExpertMain,
  },
});
